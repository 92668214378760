<template>
    <div>
        <profile-detail :profile="profile" :properties="properties"></profile-detail>

    </div>
</template>

<script>
    export default {
        name: "ConnectProfile",
        data() {
            return {
                agent_slug:this.$route.params.slug,

            }
        },
        computed: {
            profile() {
                return this.$store.state.agent_module.agent_profile;
            },
            properties(){
                return this.$store.state.agent_module.agent_profile.property
            }
        },
        watch: {
            '$route'(to, from){
                this.agent_slug = to.params.slug
            }
        },
        methods:{
            fetchProfile(){
                this.$store.dispatch("viewAgentDetailAction",this.agent_slug)
            }
        },
        created() {
            this.agent_slug =this.$route.params.slug
            this.fetchProfile();
        }
    }
</script>

<style scoped>

</style>
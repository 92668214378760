<template>
    <div><router-view></router-view></div>
</template>

<script>
    export default {
        name: "Connect",
        data() {
            return {}

        },
        methods: {
        }
    }
</script>

<style scoped>

</style>
<template>
    <div>

        <search-area :title="!apiloading?agent_developer_found:'loading...'" height="60">
            <template v-slot:divider>
                <v-divider class="mydivider"></v-divider>
            </template>
            <template v-slot:item>
                <v-tabs v-model="tab" grow icons-and-text  background-color="white" class="mytab" height="40" light  slider-size="0">
                    <v-tab href="#tab-1">
                        <!--                            <span>-->
                        <!--                                  <v-icon size="7" v-if="tab==='tab-1'">mdi-circle-medium</v-icon>-->
                        <!--                            </span>-->

                        Agents  </v-tab>
                    <v-tab href="#tab-2">
                        <!--                            <span>-->
                        <!--                                <v-icon size="7" v-if="tab==='tab-2'">mdi-circle-medium</v-icon> -->
                        <!--                            </span>-->

                        Developers</v-tab>
                </v-tabs>
            </template>
            <template v-slot:icon>
                <v-spacer></v-spacer>
                <v-btn icon @click="goToFilterAgentPage()">
                    <v-icon>mdi-filter-variant</v-icon>
                </v-btn>

            </template>
        </search-area>
        <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1" id="tab-1">
                <template v-if="!agent_is_empty">
                    <profile-list-card :data="item" v-for="(item,i) in agent_list" :key="item.id" :show_subtitle="true" icon="mdi-chevron-right"></profile-list-card>
                    <div style="text-align:center" v-if="showLoadMore">
                        <v-btn block style="color:#fff !important;font-size: 12px;text-transform: capitalize;margin-top:40px"  class="mb-20" elevation="0" type="submit" :loading="apiloading" :disabled="apiloading" width="120" color="secondary"
                               @click="loadMore">Load More</v-btn>
                    </div>
                </template>
                <empty-list v-if="agent_is_empty"></empty-list>
            </v-tab-item>

            <v-tab-item value="tab-2" id="tab-2">
                <template v-if="!developer_is_empty">
                <profile-list-card :data="item" v-for="(item,i) in developer_list" :key="item.id" :show_subtitle="true" :isActive="true" icon="mdi-chevron-right"></profile-list-card>
                <div style="text-align:center" v-if="showLoadMore">
                    <v-btn block style="color:#fff !important;font-size: 12px;text-transform: capitalize;margin-top:40px"  class="mb-20" elevation="0" type="submit" :loading="apiloading" :disabled="apiloading" width="120" color="secondary"
                           @click="loadMore">Load More</v-btn>

                </div>
                </template>
                <empty-list v-if="developer_is_empty"></empty-list>
            </v-tab-item>
        </v-tabs-items>


    </div>
</template>

<script>
    import {loadingMixin} from "../../../../mixins/Loading";

    export default {
        name: "ConnectList",
        mixins:[loadingMixin],
        data() {
            return {
                tab: '',

                nextUrl:'',
                path:'',
                showLoadMore:true,
                found_users:0,
                all_agents:[],
                all_developers:[],
                agent_is_empty:false,
                developer_is_empty:false
            }
        },
        computed: {
            agent_developer_found(){
              return this.found_users +  " Agents & Developers Found"
            },
            agent_list_type(){
                return this.$store.state.agent_module.agent_list_type;
            },
            agent_list() {
                let p = ''
                let type = this.$store.state.agent_module.agent_list_type;
                switch (type) {
                    case 'search':
                        p= this.$store.state.agent_module.agent_search_result;
                        break;
                    default:
                        p = this.$store.state.agent_module.all_agents;
                        break;
                }
                this.all_agents= p.data;
                if(p.total === 0 ){
                    this.agent_is_empty = true;
                }

                this.nextUrl = p.current_page+ 1;
                this.showLoadMore = p.current_page < p.last_page;
                this.path = p.path;
                this.found_users = p.total
                return this.all_agents;
            },
            developer_list() {
                let p = ''
                let type = this.$store.state.agent_module.agent_list_type;
                switch (type) {
                    case 'search':
                        p= this.$store.state.agent_module.agent_search_result;
                        break;
                    default:
                        p = this.$store.state.agent_module.all_developers;
                        break;
                }
                this.all_developers=p.data;
                if(p.total === 0 ){
                    this.developer_is_empty = true;
                }

                this.nextUrl = p.current_page+ 1;
                this.showLoadMore = p.current_page < p.last_page;
                // this.showLoadMore=true
                this.path = p.path;
                this.found_user += p.total;
                return this.all_developers;
            },
            nextUrlLink(){
                let path = this.$store.state.paginated_module.path;
                let page = this.$store.state.paginated_module.next;
                return this.path+"?page="+page
            },
        },
        methods: {
            goToFilterAgentPage() {
                this.$router.push({name:'filter_agent'});
            },
            fetchAllAgentsAndDevelopers(){
                this.$store.dispatch("allAgentAction")
                this.$store.dispatch("allDeveloperAction");
            },
            nextUrlAction(t){
                this.$store.dispatch("nextPageAction",t);
            },
            loadMore(){
                this.$store.dispatch("loadMoreAction",this.nextUrlLink).then((res)=>{
                    console.log('pad',res)

                    if(res.last_page === res.current_page && res.total <=10){
                        return this.showLoadMore = false;
                    }
                    res.data.forEach(data=>{
                        this.properties.push(data);
                    })
                    this.nextUrlAction({next_page_url:res.next_page_url,current_page:res.current_page});

                    return this.showLoadMore = true;
                })
            },
        },
        created() {
            this.fetchAllAgentsAndDevelopers()
        }
    }
</script>

<style scoped>
    .mytab{
        padding-top:-16px !important;
        /*margin-top:15px;*/
    }
    .v-card__subtitle, .v-card__text, .v-card__title {
        padding: 2px !important;
    }
    .v-tabs--icons-and-text > .v-tabs-bar .v-tab {
        text-transform: capitalize;
        text-decoration: none !important;
    }
</style>